import useApiCall from "hooks/useApiCall";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useEffect, useState } from "react";
import checkFiltering from "utils/checkFiltering";
import { viewAllApplication } from "../api/kings";
import Header from "./Header";
import MainScreenFilter from "./filter/MainScreenFilter";
import MainTable from "./table/MainTable";

const KingsApplication = () => {
  const [activeMenu, setActiveMenu] = useState("");
  const [allApplications, setAllApplications] = useState([]);
  // Helper states
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const isFiltering = checkFiltering(filters);

  const { data, loading, fetchData: fetchListData } = useApiCall();

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;

    fetchListData(
      viewAllApplication,
      {
        body_data: finalFilters,
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, search, pagination?.current_page, pagination?.per_page]);

  useDidMountEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllApplications(allData);
      setPagination(rest);
    }
  }, [data]);

  const all_props = {
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allApplications,
    setAllApplications,
  };

  return (
    <div>
      <Header
        {...{
          activeMenu,
          setActiveMenu,
          setSearch,
          setPagination,
          pagination,
          isFiltering,
          search,
          filters,
        }}
      />

      <MainScreenFilter {...all_props} />

      <MainTable loading={loading} {...all_props} />
    </div>
  );
};

export default KingsApplication;
