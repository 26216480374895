import { Edit } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useFormatter from "hooks/useFormatter";
import useQueries from "hooks/useQueries";
import { MdDelete } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleEdit,
  handleView,
  handleTableCheckBox,
  selectedRows,
  permissions,
  ...restProps
}) => {
  const { setQueries } = useQueries();
  const { formatDate } = useFormatter();

  return (
    <TableRow
      {...restProps}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px",
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "8px",
          paddingRight: "0px",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
          disabled={!permissions["bulk action"]}
        />
      </TableCell>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.format && typeof value === "number" ? (
              column.format(value)
            ) : column.id === "expense_date" || column.id === "created_at" ? (
              formatDate(value)
            ) : column.id === "total" ? (
              <Typography color="#7171ff" variant="body2">
                {row.symbol}
                {value + row.tax - row.discount}
              </Typography>
            ) : column.id === "subtotal" ? (
              <Typography variant="body2">{row.symbol + value}</Typography>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Details">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=1`)}>
                    <TbListDetails />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setQueries(`id=${row?.id}&m=0`)}
                    disabled={!permissions.update}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    disabled={!permissions.trash}
                  >
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value || "-"
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
