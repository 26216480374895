import { Box, Button } from "@mui/material";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useBase from "hooks/useBase";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateSMSSettings } from "../api/settings";

const SMS = ({ sms }) => {
  const { updateBase } = useBase();

  const initialValues = {
    user_name: sms?.user_name || "",
    password: sms?.password || "",
    base_url: sms?.base_url || "",
    balance_url: sms?.balance_url || "",
    source: sms?.source || "",
    sms_type: sms?.sms_type || "",
  };

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    const result = await updateSMSSettings({ body_data: data });
    if (result.success) {
      toast.success(result.message);
      updateBase(["settings"]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{
        width: "650px",
        margin: "0 auto",
        border: "1px solid #efefef",
        borderRadius: "5px",
        padding: "15px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <InputField name="user_name" formik={formik} label="Username" />
      <InputField name="password" formik={formik} label="Password" />
      <InputField name="source" formik={formik} label="Source" />
      <InputField name="base_url" formik={formik} label="Base URL" />
      <InputField name="balance_url" formik={formik} label="Balance URL" />
      <Select
        name="sms_type"
        options={[
          { label: "Masking", id: "masking" },
          { label: "Non Masking", id: "non_masking" },
        ]}
        label="SMS Type"
        formik={formik}
      />

      <Button
        variant="solid"
        onClick={formik.submitForm}
        fullWidth
        disabled={!(formik.dirty && formik.isValid)}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default SMS;
