import { Box, Button, Typography } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useBase from "hooks/useBase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateDateTimelSettings } from "../api/settings";

const AppSettings = ({ app, timezones }) => {
  const { updateBase } = useBase();
  const currencies = useSelector((state) => state?.base?.base_data?.currencies);
  const initialValues = {
    currency_id: app?.currency_id || "",
    password_reset_expiration: app?.password_reset_expiration || "",
    default_checkin: app?.default_checkin || null,
    default_checkout: app?.default_checkout || null,
    date_format: app?.date_format || "",
    time_format: app?.time_format || "",
    timezone: app?.timezone || "",
    backup_email: app?.backup_email || "",
    // sms
    mram_username: app?.mram_username || "",
    mram_password: app?.mram_password || "",
    mram_sender_id: app?.mram_sender_id || "",
  };

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    const result = await updateDateTimelSettings({ body_data: data });
    if (result.success) {
      toast.success(result.message);
      updateBase(["settings"]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{
        width: "650px",
        margin: "0 auto",
        border: "1px solid #efefef",
        borderRadius: "5px",
        padding: "15px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <Select
        options={currencies}
        label="Currency *"
        value={formik?.values?.currency_id}
        onChange={(value) => formik.setFieldValue("currency_id", value)}
        selectProps={{ id: "id", label: "name" }}
      />
      <DateTimePicker
        name="default_checkin"
        label="Default Checkin"
        type="time"
        formik={formik}
      />
      <DateTimePicker
        name="default_checkout"
        label="Default Checkout"
        type="time"
        formik={formik}
      />
      <InputField
        name="password_reset_expiration"
        formik={formik}
        label="Password Reset Expiration"
      />
      <Select
        options={[
          { label: "Default Format (ex- Mar 29, 2002)", id: "default" },
          { label: "DD/MM/YYYY (ex- 29/03/2002)", id: "DD/MM/YYYY" },
          { label: "YYYY/MM/DD (ex- 2002/03/29)", id: "YYYY/MM/DD" },
        ]}
        label="Date Format"
        value={formik?.values?.date_format}
        onChange={(value) => formik.setFieldValue("date_format", value)}
      />
      <Select
        options={[
          { label: "HH/MM/SS", id: "HH/MM/SS" },
          { label: "SS/MM/HH", id: "SS/MM/HH" },
        ]}
        label="Time Format"
        value={formik?.values?.time_format}
        onChange={(value) => formik.setFieldValue("time_format", value)}
      />
      <Select
        options={timezones}
        label="Timezone"
        value={formik?.values?.timezone}
        onChange={(value) => formik.setFieldValue("timezone", value)}
      />
      <InputField name="backup_email" formik={formik} label="Backup Email" />

      <Typography variant="h5">SMS Credentials</Typography>
      <InputField
        name="mram_username"
        formik={formik}
        label="SMS API Username"
      />
      <InputField
        name="mram_password"
        formik={formik}
        label="SMS API Password"
      />
      <InputField
        name="mram_sender_id"
        formik={formik}
        label="SMS API Sender ID"
      />

      <Button
        variant="solid"
        onClick={formik.submitForm}
        fullWidth
        disabled={!(formik.dirty && formik.isValid)}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default AppSettings;
