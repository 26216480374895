import { Avatar, Button, TableCell, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useFormatter from "hooks/useFormatter";

const AvatarInfo = ({
  item = {},
  size = "100%",
  truncate,
  title = item.name,
}) => {
  return (
    <Box
      sx={{
        minWidth: "150px",
        display: "flex",
        gap: "10px",
        alignItems: "center",

        "& span": {
          maxWidth: size,
          whiteSpace: truncate ? "nowrap" : "normal",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
      title={title}
    >
      {item?.image?.includes("http") && (
        <Avatar
          sx={{ height: "32px", width: "32px" }}
          alt={item?.name}
          src={item?.image?.replace("/original/", "/small/")}
        />
      )}

      <Typography component="span" variant="body">
        {item?.name || "-"}
      </Typography>
    </Box>
  );
};

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  setReload,
  openNote,
  setOpenNote,
  handleTableCheckBox,
  selectedRows,
  allLeads,
  setAllLeads,
  permissions,
  ...restProps
}) => {
  const { formatDate } = useFormatter();

  return (
    <TableRow
      {...restProps}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 16px",
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "20px",
        },
      }}
    >
      {columns.map((column) => {
        const value = row[column.id];

        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.id === "apply_date" ? (
              <Typography variant="body2">{formatDate(value)}</Typography>
            ) : column.id === "program_name" ||
              column.id === "institute_name" ? (
              <Typography
                variant="body"
                sx={{
                  maxWidth: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                title={value}
              >
                {value}
              </Typography>
            ) : column.id === "master_agent" ? (
              row.recruiter?.[0] ? (
                <AvatarInfo
                  item={{
                    name: row.recruiter[0].master_agent_name,
                    image: row.recruiter[0].master_agent_image,
                  }}
                />
              ) : (
                "-"
              )
            ) : column.id === "sub_agent" ? (
              row.recruiter?.[0] ? (
                <AvatarInfo
                  item={{
                    name: row.recruiter[0].name,
                    image: row.recruiter[0].image,
                  }}
                />
              ) : (
                "-"
              )
            ) : column.id === "offer_letter" ? (
              row?.offer_letter ? (
                <>
                  {row?.offer_letter_is_delete ? (
                    <>
                      <Typography variant="body2" color={"error.main"}>
                        Deleted
                      </Typography>
                      <Typography variant="body2">
                        {formatDate(row?.offer_letter_deleted_at)}
                      </Typography>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.open(row?.offer_letter, "_blank");
                      }}
                    >
                      Download
                    </Button>
                  )}
                </>
              ) : (
                "-"
              )
            ) : (
              value || "-"
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
