import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import Editor from "components/ui/editor/Editor";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import Axios from "lib/Axios";
import { useEffect, useState } from "react";
import * as yup from "yup";

const projectsList = ({ id }) =>
  Axios.get(`/api/admin/base/project?customer_id=${id}`).then(
    (res) => res.data
  );

const AddEdit = ({
  openDrawer,
  setOpenDrawer,
  currTicket,
  setAllTickets,
  allTickets,
  customer_id,
  setSingleTicket,
  setTabValue,
  tabValue,
  setUpdateCount,
}) => {
  const [projects, setProjects] = useState([]);

  const initialValues = {
    customer_id,
    project_id: currTicket?.project_id || projects[0]?.id,
    title: currTicket?.title || "",
    description: currTicket?.description || "",
  };

  const validationSchema = yup.object({
    project_id: yup.string("").required("This field is Required").nullable(),
    title: yup.string("").required("Title is Required").nullable(),
    description: yup.string("").required("Description is Required").nullable(),
  });

  const { loading, fetchData } = useApi();

  const onSubmit = async (data) => {
    let endpoint;
    if (currTicket?.id) {
      endpoint = {
        method: "put",
        url: `/api/ticket/update/${currTicket.id}`,
        data,
      };
    } else {
      endpoint = {
        method: "post",
        url: "/api/ticket/create",
        data,
      };
    }

    const result = await fetchData(endpoint, false);
    if (result.success) {
      if (currTicket?.id) {
        setSingleTicket(result.data);
      } else {
        setAllTickets([result?.data, ...allTickets]);
        tabValue !== "1" && setTabValue("1");
        setUpdateCount(true);
      }
      setOpenDrawer(false);
    }
  };
  useEffect(() => {
    const getProjects = async () => {
      const result = await projectsList({
        id: customer_id || "",
      });
      if (result.success) setProjects(result.data);
    };
    getProjects();
  }, []);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  return (
    <>
      {openDrawer && (
        <Box p="20px">
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            sx={{
              mb: 3,
            }}
            onClick={() => setOpenDrawer(false)}
          >
            Back
          </Button>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "20px",
            }}
          >
            <Select
              options={projects}
              label="Project *"
              value={formik?.values?.project_id}
              onChange={(value) => formik.setFieldValue("project_id", value)}
              selectProps={{ id: "id", label: "name" }}
              sx={{ gridColumn: "span 2" }}
            />

            <InputField
              sx={{ gridColumn: "span 2" }}
              name="title"
              label="Complain/Complement Title *"
              formik={formik}
            />
            <Box sx={{ gridColumn: "span 2" }}>
              <Editor formik={formik} />
            </Box>
          </Box>
          <Box sx={{ mt: "20px" }}>
            <Button
              variant="solid"
              onClick={formik.submitForm}
              fullWidth
              disabled={!(formik.dirty && formik.isValid) || loading}
            >
              {currTicket?.id ? "Update Complain/Complement" : "Save Complain/Complement"}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AddEdit;
