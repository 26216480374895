import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Note from "components/common/note";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import useWindowSize from "hooks/useWindowSize";
import React, { useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import MainTableRow from "./MainTableRow";

const ExpandableRow = ({ context, item: row, ...restProps }) => {
  return <MainTableRow {...restProps} {...context} key={row.id} row={row} />;
};

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MainTable = ({
  allApplications,
  setAllApplications,
  handleDelete,
  setReload,
  pagination,
  setPagination,
  loading,
  handleTableCheckBox,
  selectedRows,
  permissions,
}) => {
  const { isMini } = useWindowSize();
  const [openNote, setOpenNote] = useState("");
  const rows = allApplications;
  const columns = [
    { id: "student_name", label: "Student Name" },
    {
      id: "institute_name",
      label: "Institute",
    },
    { id: "program_name", label: "Program" },
    {
      id: "intake_name",
      label: "Intake",
    },
    {
      id: "master_agent",
      label: "Master Agent",
    },
    {
      id: "sub_agent",
      label: "Sub Agent",
    },
    { id: "apply_date", label: "Apply Date" },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "offer_letter",
      label: "Offer Letter",
      align: "center",
    },
  ];

  const height = isMini ? "calc(100vh - 201px)" : "calc(100vh - 257px)";

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer
          sx={{
            height,
            overflowX: "auto",
          }}
        >
          <TableVirtuoso
            style={{ height }}
            data={rows}
            components={TableComponents}
            context={{
              columns,
              handleDelete,
              openNote,
              setOpenNote,
              handleTableCheckBox,
              selectedRows,
              permissions,
              setReload,
              allApplications,
              setAllApplications,
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 16px",
                  },
                  "& .MuiTableCell-sizeMedium:first-of-type": {
                    paddingLeft: "20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    sx={{ bgcolor: "#fff" }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          />
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight={height} />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Paper>
  );
};

export default MainTable;
