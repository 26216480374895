import { Box, Button } from "@mui/material";
import DateRangeField from "components/ui/dateRangeField";
import MultiSelect from "components/ui/multiSelect";
import getInitialvalues from "features/leads/utils/filterInitialValues";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import * as yup from "yup";

export const useCustomApi2 = (apiPath, params) => {
  const { fetchData, loading } = useApi();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchData(
          {
            method: "get",
            url: "https://leads.applykings.com" + apiPath + (params || ""),
          },
          false
        );
        if (result.success) {
          setData(result.data || []);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return { data, loading };
};

const MainScreenFilter = ({
  setReload,
  setFilters,
  setPagination,
  pagination,
  filters,
  isFiltering,
}) => {
  const [reset, setReset] = useState(false);
  const initialValues = getInitialvalues(filters);

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
    setReset((pre) => !pre);
  };

  const { data: masterAgents } = useCustomApi2("/api/master-agent");
  const { data: subAgents } = useCustomApi2("/api/sub-agent");
  const { data: intakes } = useCustomApi2("/api/base/intake");

  return (
    <Box
      p="15px 20px 5px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "18px",
      }}
    >
      <MultiSelect
        options={masterAgents}
        label="Master Agents"
        value={formik?.values?.user_id}
        onChange={(value) => formik.setFieldValue("user_id", value)}
      />
      <MultiSelect
        options={subAgents}
        label="Sub Agents"
        value={formik?.values?.sub_agent}
        onChange={(value) => formik.setFieldValue("sub_agent", value)}
      />
      <MultiSelect
        options={intakes}
        label="Intakes"
        value={formik?.values?.offer_intake_id}
        onChange={(value) => formik.setFieldValue("offer_intake_id", value)}
      />

      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!formik.dirty}
        >
          Filter
        </Button>
        <Button variant="outlined" disabled={!isFiltering} onClick={resetForm}>
          Reset Filter
        </Button>
      </Box>
    </Box>
  );
};

export default MainScreenFilter;
