import { Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useState } from "react";

const Header = ({ setSearch, setPagination, pagination }) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useDidMountEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
      </Grid>
    </Grid>
  );
};

export default Header;
