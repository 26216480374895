import {
  Add,
  DownloadOutlined,
  FilterAltOutlined,
  TimelineOutlined,
} from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import useDidMountEffect from "hooks/useDidMountEffect";
import useQueries from "hooks/useQueries";
import { useState } from "react";
const Header = ({
  setSearch,
  setPagination,
  pagination,
  permissions,
  setActiveMenu,
  activeMenu,
  setOpenFilter,
  setOpenExport,
}) => {
  const { setQueries } = useQueries();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useDidMountEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!permissions["list"] && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          disabled={!permissions.summery}
          variant={activeMenu === "summary" ? "solid" : "outlined"}
          startIcon={<TimelineOutlined />}
          onClick={() =>
            setActiveMenu(activeMenu === "summary" ? "" : "summary")
          }
        >
          Summary
        </Button>
        <Button
          variant={"outlined"}
          startIcon={<FilterAltOutlined />}
          onClick={() => setOpenFilter(true)}
          disabled={!permissions.list}
        >
          Advanced Filters
        </Button>
        <Button
          variant="outlined"
          startIcon={<DownloadOutlined />}
          onClick={() => setOpenExport(true)}
          disabled={!permissions.export}
        >
          Export
        </Button>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setQueries("m=0")}
          disabled={!permissions.create}
        >
          New Bank Invoice
        </Button>
      </Box>
    </Grid>
  );
};

export default Header;
