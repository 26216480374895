import { Box, Button, Stack, Typography } from "@mui/material";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

let validationSchema = yup.object().shape({
  message: yup
    .string()
    .max(1000, "1000 character max")
    .required("Required field"),
});
const SMS = ({ leadId }) => {
  const templateList = useSelector(
    (state) => state.base.base_data.smsTemplates
  );
  const user_id = useSelector((state) => state?.auth?.user_details?.user?.id);

  const { app, maskingSms } =
    useSelector((state) => state?.base?.base_data?.settings) || {};
  let checkSMSapi =
    app?.mram_username && app?.mram_password && app?.mram_sender_id
      ? true
      : maskingSms?.user_name &&
        maskingSms?.password &&
        maskingSms?.base_url &&
        maskingSms?.balance_url &&
        maskingSms?.source &&
        maskingSms?.sms_type
      ? true
      : false;

  const initialValues = {
    lead_id: [leadId],
    message: "",
    template_id: "",
  };
  const { loading: loadingSendMsg, fetchData: sendMsgApi } = useApi();
  const { loading: loadingSendMsgReport, fetchData: sendMsgTrportApi } =
    useApi();

  const onSubmit = async (data, action) => {
    let endpoint = {
      method: "post",
      url: "/api/admin/sms/send",
      data,
    };

    const result = await sendMsgApi(endpoint);
    if (result.success) {
      if (result?.data[0]?.status === "Delivered") {
        await sendMsgTrportApi({
          method: "get",
          url: `/api/admin/sms/report/${result?.data[0]?.sender_id}`,
        });
      }

      action.resetForm();
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (templateList?.length > 0) {
      let defaultValue = templateList?.find((item) =>
        item?.user_id === user_id ? true : item?.is_default === 1
      )?.id;
      formik.setFieldValue("template_id", defaultValue);
      formik.setFieldValue(
        "message",
        defaultValue
          ? templateList.find((item) => item?.id === defaultValue)?.body
          : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {checkSMSapi ? (
        <form onSubmit={formik.handleSubmit}>
          <Stack direction={"column"} gap={2}>
            <Box sx={{ display: "flex", width: "200px", ml: "auto" }}>
              <Select
                options={templateList}
                label={"Use Template"}
                value={formik?.values?.template_id}
                onChange={(value) => {
                  formik.setFieldValue("template_id", value);
                  formik.setFieldValue(
                    "message",
                    value
                      ? templateList.find((item) => item.id === value).body
                      : ""
                  );
                }}
                selectProps={{ id: "id", label: "title" }}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                flexGrow: 1,
                mt: 2,
              }}
            >
              <InputField
                fullWidth
                name="message"
                label="Message *"
                formik={formik}
                multiline
                rows={10}
              />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "end",
                  fontSize: "12px",
                  position: "absolute",
                  top: "-20px",
                  right: "8px",
                }}
              >
                {formik.values.message.length}/1000
              </Typography>
            </Box>

            <Button
              type="submit"
              variant="solid"
              fullWidth
              disabled={!formik.dirty || loadingSendMsg || loadingSendMsgReport}
            >
              Send Message
            </Button>
          </Stack>
        </form>
      ) : (
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Please fill up SMS API credential.
          <br />
          Go to {`Setup > Settings > app `}
        </Typography>
      )}
    </>
  );
};

export default SMS;
