import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "components/alert";
import TableSkeleton from "components/skeletons/TableSkeleton";
import {
  deleteUniCom,
  updateComStatus,
} from "features/invoices/commissions/api/commissions";
import useFormatter from "hooks/useFormatter";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
const statuses = [
  { id: "paid", name: "Paid", color: "#008000" },
  { id: "pending", name: "Pending", color: "#FF0000" },
];
const CommissionTable = ({
  profile,
  loading,
  allCommission,
  setAddCommission,
  setCurrCommission,
  setAllCommission,
  setReload,
}) => {
  const { formatDate } = useFormatter();
  const rows = allCommission;
  const columns = [
    { id: "semester_name", label: "Semester Name" },
    { id: "university_commission_amount", label: "Amount" },
    { id: "university_commission_date", label: "Date" },
    { id: "university_commission_due_date", label: "Due Date" },
    { id: "status", label: "Status" },
    {
      id: "action",
      label: "Actions",
      style: { width: "130px" },
      align: "center",
    },
  ];
  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    const result = await deleteUniCom(openAlert);
    if (result.success) {
      toast.success(result.message);
      setAllCommission(allCommission.filter((item) => item.id !== openAlert));
      setReload((prev) => !prev);
    } else {
      toast.error(result.message);
    }
    setOpenAlert(false);
  };

  const handleEditBtn = (value) => {
    setCurrCommission(value);
    setAddCommission(true);
  };
  const handleChangeStatus = async (value, row) => {
    setAllCommission(
      allCommission.map((item) =>
        item.id === row.id
          ? {
              ...row,
              status: value,
            }
          : item
      )
    );
    try {
      let res = await updateComStatus({
        body_data: { status: value, type: "university" },
        id: row?.id,
      });
      res?.success && setReload((prev) => !prev);
    } catch (error) {}
  };
  return (
    <Fragment>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Commission"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this commission
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      {!loading ? (
        <>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 256px)",
              overflowX: "auto",
              ml: "-20px",
              mr: "-20px",
              width: "calc(100% + 40px)",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "university_commission_date" ||
                            column.id === "university_commission_due_date" ? (
                              formatDate(value)
                            ) : column.id === "status" ? (
                              <TextField
                                size="small"
                                value={value}
                                select
                                sx={{
                                  width: "100px",
                                  color: "#222",
                                  textAlign: "left",
                                  "& > div:before": { content: "none" },
                                  "& div": {
                                    color: statuses.find((i) => i?.id === value)
                                      ?.color,
                                  },
                                  "& svg": {
                                    color: statuses.find((i) => i?.id === value)
                                      ?.color,
                                  },
                                }}
                                variant="standard"
                                onChange={(e) =>
                                  handleChangeStatus(e.target.value, row)
                                }
                              >
                                {statuses?.length > 0 &&
                                  statuses?.map((status) => (
                                    <MenuItem
                                      value={status?.id}
                                      key={status?.id}
                                    >
                                      {status?.name}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            ) : column?.id ===
                              "university_commission_amount" ? (
                              <Typography>
                                {profile?.currency_symbol}
                                {value}
                              </Typography>
                            ) : column.id === "action" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => handleEditBtn(row)}>
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
    </Fragment>
  );
};

export default CommissionTable;
