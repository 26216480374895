import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Grid, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Alert from "components/alert";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddEdit from "./AddEdit";
import ReplyForm from "./ReplyForm";
import ReplyList from "./ReplyList";

import { IconButton, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import AddAttachment from "./AddAttachment";

const SingleTicket = () => {
  const customer_id = useSelector((state) => state.auth.user_details.user.id);
  const {
    base_data: { projects },
  } = useSelector((state) => state.base);
  let [singleTicket, setSingleTicket] = useState({});
  let { ticketId } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSidebarAttach, setOpenSidebarAttach] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  let naviagte = useNavigate();

  const handleDelete = async () => {
    setOpenAlert(ticketId);
  };

  let [replys, setReplys] = useState([]);
  const { loading: listReplayLoading, fetchData: listReply } = useApi();
  const { fetchData: deleteTicket } = useApi();

  useEffect(() => {
    async function allReply() {
      const endpoint = {
        method: "get",
        url: `api/ticket/reply/list/${ticketId}`,
      };
      const result = await listReply(endpoint, false);
      setReplys(result.data);
    }
    allReply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, fetchData: singleTicketApi } = useApi();

  useEffect(() => {
    const getSingleTicket = async () => {
      const endpoint = {
        method: "get",
        url: `/api/ticket/details/${ticketId}`,
      };
      const result = await singleTicketApi(endpoint, false);
      if (result.success) {
        setSingleTicket(result.data);
      }
    };
    getSingleTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    let endpoint = {
      method: "delete",
      url: `/api/ticket/delete/${ticketId}`,
    };
    const result = await deleteTicket(endpoint, false);

    if (result.success) {
      naviagte("/customer/ticket/");
    }
  };
  return (
    <>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Complain/Complement"
          desc="Are you sure you want to delete?"
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}

      <AddEdit
        {...{
          openDrawer: openSidebar,
          setOpenDrawer: setOpenSidebar,
          currTicket: singleTicket,
          setSingleTicket,
          projects,
          customer_id,
        }}
      />

      <AddAttachment
        {...{
          openDrawer: openSidebarAttach,
          setOpenDrawer: setOpenSidebarAttach,
          ticketId,
        }}
      />

      {!openSidebar && !openSidebarAttach ? (
        <>
          <Box
            sx={{
              mt: 3,
              px: 3,
            }}
          >
            {loading ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                  <div
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />{" "}
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                  </div>
                </Box>
                {[...Array(10)].map((_, i) => (
                  <Skeleton
                    key={i}
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                ))}{" "}
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography variant="h2">
                        {singleTicket?.title}
                      </Typography>
                      <SingleTicketAction
                        setOpenSidebar={setOpenSidebar}
                        setOpenSidebarAttach={setOpenSidebarAttach}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">Project Name:</Typography>
                    <Typography variant="span">
                      {singleTicket?.project_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="span">
                      {moment(singleTicket?.created_at).format("ll")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: singleTicket?.description,
                      }}
                    ></Typography>
                  </Grid>
                </Grid>
              </>
            )}

            <ReplyForm ticketId={ticketId} setReplys={setReplys} />
            <ReplyList
              setReplys={setReplys}
              loading={listReplayLoading}
              replys={replys}
            />
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SingleTicket;

function SingleTicketAction({
  setOpenSidebar,
  setOpenSidebarAttach,
  handleDelete,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteConf = async () => {
    handleDelete();
  };
  const handleEdit = () => {
    setOpenSidebar(true);
  };
  const handleAttachment = () => {
    setOpenSidebarAttach(true);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleDeleteConf();
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleEdit();
          }}
        >
          Edit
        </MenuItem>{" "}
        <MenuItem
          onClick={() => {
            handleClose();
            handleAttachment();
          }}
        >
          Attachment
        </MenuItem>
      </Menu>
    </div>
  );
}
