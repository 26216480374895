import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DateTimePicker from "components/ui/dateTimePicker";
import Select from "components/ui/select";
import {
  sponsorProfessions,
  sponsorProfessionsFields,
} from "features/projectCustomers/api/projectCustomer";
import CheckDateBeforeToday from "features/projectCustomers/utils/CheckDateBeforeToday";
import { useEffect, useState } from "react";

const ProfessionFields = ({ formik }) => {
  const [professionTypes, setProfessionTypes] = useState([]);
  const [professionFields, setProfessionFields] = useState([]);

  const getProfessionName = (id) =>
    professionTypes?.find((pt) => pt?.id === id)?.job_name;

  const getFieldIndex = (id) =>
    selectedProfessions.findIndex((sp) => sp.pc_sponsor_job_field_id === id);

  const selectedProfessionsId = formik?.values?.selected_professions_id;
  const selectedProfessions = formik?.values?.sponsor_professions;
  const [isAddProfessionActive, setIsAddProfessionActive] = useState(false);

  const handleProfessionChange = (profession_id) => {
    setIsAddProfessionActive(false);
    const newProfessionFields = professionFields.filter(
      (field) => field.pc_sponsor_job_id === profession_id
    );

    formik.setFieldValue("sponsor_professions", [
      ...newProfessionFields.map((field) => {
        return {
          field_name: field?.field_name,
          pc_sponsor_job_id: field?.pc_sponsor_job_id,
          pc_sponsor_job_field_id: field?.id,
          value_bool: false,
          value_date: null,
        };
      }),
      ...selectedProfessions,
    ]);

    formik.setFieldValue("selected_professions_id", [
      profession_id,
      ...selectedProfessionsId,
    ]);
  };

  const handleDeleteProfession = (profession_id) => {
    formik.setFieldValue(
      "sponsor_professions",
      selectedProfessions?.filter(
        (profession) => profession?.pc_sponsor_job_id !== profession_id
      )
    );

    formik.setFieldValue(
      "selected_professions_id",
      selectedProfessionsId?.filter((id) => id !== profession_id)
    );
  };

  useEffect(() => {
    const getProfession = async () => {
      const result = await sponsorProfessions();
      result?.success && setProfessionTypes(result.data);
      const spfResult = await sponsorProfessionsFields();
      spfResult?.success && setProfessionFields(spfResult.data);
    };
    getProfession();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          gridColumn: "span 2",
          borderBottom: "1px solid #ddd",
          pb: "10px",
          m: "35px 0 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color={(theme) => theme.palette.primary.main}>
          Income Source
        </Typography>
        {professionTypes?.length !== selectedProfessionsId?.length && (
          <Button
            disabled={isAddProfessionActive}
            startIcon={<Add />}
            onClick={() => setIsAddProfessionActive(true)}
          >
            {selectedProfessionsId?.length
              ? "Add another Income Source"
              : "Add Income Source"}
          </Button>
        )}
      </Box>
      {isAddProfessionActive && (
        <Select
          label="Select Profession"
          options={professionTypes.filter(
            (pt) => !selectedProfessionsId.includes(pt?.id)
          )}
          onChange={(value) => handleProfessionChange(value)}
          selectProps={{ id: "id", label: "job_name" }}
        />
      )}
      {selectedProfessionsId?.length > 0 &&
        selectedProfessionsId.map((sp_id) => (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "15px",
              margin: "10px 0 20px",
            }}
          >
            <Box
              sx={{
                gridColumn: "span 2",
                m: "10px 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{getProfessionName(sp_id)} :</Typography>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteProfession(sp_id)}
              />
            </Box>
            {selectedProfessions
              ?.filter((sp, i) => sp.pc_sponsor_job_id === sp_id)
              ?.map((profession) => (
                <Stack>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={profession?.field_name}
                    checked={
                      Number(
                        formik?.values?.sponsor_professions[
                          getFieldIndex(profession.pc_sponsor_job_field_id)
                        ]?.value_bool
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `sponsor_professions[${getFieldIndex(
                          profession.pc_sponsor_job_field_id
                        )}].value_bool`,
                        e.target.checked ? 1 : 0
                      );
                    }}
                  />
                  <CheckDateBeforeToday
                    givenDate={
                      Number(
                        formik?.values?.sponsor_professions[
                          getFieldIndex(profession.pc_sponsor_job_field_id)
                        ]?.value_bool
                      )
                        ? null
                        : formik?.values?.sponsor_professions[
                            getFieldIndex(profession.pc_sponsor_job_field_id)
                          ]?.value_date
                    }
                  >
                    <DateTimePicker
                      isComplex={true}
                      name={`sponsor_professions[${getFieldIndex(
                        profession.pc_sponsor_job_field_id
                      )}].value_date`}
                      label={
                        Number(
                          formik?.values?.sponsor_professions[
                            getFieldIndex(profession.pc_sponsor_job_field_id)
                          ]?.value_bool
                        )
                          ? "Receive Date"
                          : "Due Date"
                      }
                      formik={formik}
                    />
                  </CheckDateBeforeToday>
                </Stack>
              ))}
          </Box>
        ))}
    </LocalizationProvider>
  );
};

export default ProfessionFields;
